@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'body';
  src: url('../fonts/Gellix-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'heading';
  src: url('../fonts/Gellix-SemiBold.ttf') format('truetype');
}

*,
body {
  font-family: 'body', Helvetica, Arial, serif !important;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'heading', 'Helvetica', 'Arial', serif !important;
}

html {
  scroll-behavior: smooth;
}

#login *,
body {
  font-family: 'body', Helvetica, Arial, sans-serif !important;
}
customBold {
  font-family: 'heading', 'Helvetica', 'Arial', serif !important;
}

.reset-password {
  font-family: Helvetica, Arial, serif !important;
}

.PhoneInputInput {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 12px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(51, 51, 51, 1);
}

.onboarding .PhoneInputInput {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #000;
  background-color: initial;
}

/* scrollbar style */
/* width */
::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.srcollTopics::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
.srcollTopics::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.srcollTopics::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 1rem;
}

/* Handle on hover */
.srcollTopics::-webkit-scrollbar-thumb:hover {
  background: #888;
  width: 0.5rem;
}

.scrollbar-hidden {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* community-scrollbar */
.community-scrollbar::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  width: 6px;
}
.community-scrollbar::-webkit-scrollbar-thumb {
  background-color: #f00037;
  border-radius: 100px;
  height: 46px;
}

input.disabled-arrows::-webkit-outer-spin-button,
input.disabled-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.disabled-arrows {
  -moz-appearance: textfield;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

details > summary::marker, /* Latest Chrome, Edge, Firefox */
details > summary::-webkit-details-marker /* Safari */ {
  display: none;
}

summary {
  display: block;
  position: relative;
}

summary::after {
  float: right;
  cursor: pointer !important;
  transition: 0.2s;
  font-size: 1.8rem;
  opacity: 0.5;
  content: '\002B'; /* chevron */
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
}

details[open] summary::after {
  content: '\2212';
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
}

/* change active bullet color to red */
.swiper-pagination-bullet-active {
  opacity: 1;
  /* background: var(--swiper-pagination-color, var(--swiper-theme-color)); */
  background-color: #f00037 !important;
}

button:disabled {
  @apply opacity-50 pointer-events-none;
}

/* css config for package `react-quill` */
.ql-container {
  font-size: 1rem !important;
}

.active-navlink:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: background-color 0.2s ease-in-out;
  z-index: 50;
  @apply bg-learn-primary;
}

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

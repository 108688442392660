.swiper-button-next {
  right: 0rem !important;
}
.swiper-button-prev {
  left: 0rem !important;
}

.swiper-button-next,
.swiper-button-prev {
  height: 3.5rem;
  width: 2rem;
  padding: 1rem !important;
  /* background-color: white !important; */
  background-image: url(./next-alma.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-size: 1.3rem;
  position: absolute;
  top: 55% !important;
  /* position: absolute; */
  /* bottom: 0; */
  /* top: 100; */
  /* right: 20px; */
}

.swiper-button-prev {
  transform: rotate(180deg) !important;
}
.swiper-button-prev::after {
  display: none;
}
.swiper-button-next::after {
  display: none;
}

@media (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}

.customScroll {
  overflow-x: scroll;
  scroll-behavior: smooth;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.customScroll::-webkit-scrollbar {
  display: none;
}

.course-plan-card {
  position: relative;
}
.course-plan-card::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 5px;
  background: linear-gradient(130deg, #f00037 0%, rgba(204, 19, 19, 0) 70%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.cardScroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.radialGradient {
  background-image: radial-gradient(at 8% -290%, transparent 75%, #9e8beb 75% 77%, #9380e1 77% 100%),
    linear-gradient(to right, #d5cbff 0%, #a88df4 100%);
}

@media screen and (max-width: 768px) {
  .radialGradient {
    background-image: radial-gradient(
        at 200% 10%,
        transparent 75%,
        #9e8beb 75% 77%,
        #9380e1 77% 100%
      ),
      linear-gradient(to right, #d5cbff 0%, #a88df4 100%);
  }
}

.paymentCard--white {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; /* Optional: Add shadow for depth */

  /* Linear gradient for premium white color with border */
  background-image: linear-gradient(to top left, #ffffff, #f8f8f8),
    linear-gradient(145deg, rgba(255, 255, 255, 0.6), transparent);
  border: 1px solid #ede7e7ba; /* Transparent border */
}

.emi-table--head th {
  background-color: #fcfcfd;
  color: #475467;
  border-bottom: 2px;
  min-width: 200px;
  padding: 12px 24px;
  font-weight: 500;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
}

.emi-table--body td {
  padding: 12px 24px;
  color: #475467;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.slim-scroll {
  overflow: scroll;
}

.slim-scroll:hover::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
}

.slim-scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: transparent;
}

.zammad-form-control {
  outline: none;
  border-radius: 8px;
  border: 1px solid #a3a3a3;
  padding: 8px 12px;
  color: darkslategray;
  font-size: 16px;
  line-height: 24px;
}

.zammad-form-group label {
  color: #273444;
}

.zammad-form .btn {
  color: white;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 24px;
  background: #f00037;
  border: 1px solid #f00037;
  outline: none !important;
  border-radius: 8px;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  width: 100%;
}
